/* eslint-disable no-self-compare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import '../App.css'
import FairLaunchFactoryAbi from '../config/FairLaunchFactoryAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import TextArea from '../components/TextArea.tsx'
import { writeContract, readContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'

import Web3 from 'web3'
import { useWeb3Modal } from '@web3modal/react'
import { toast } from 'react-hot-toast'
import calendarIcon from '../icons/calendar.png'
import Footer from '../components/Footer.jsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar.jsx'
import LogoUploadBox from '../components/LogoUploadBox.jsx'
import BannerUploadBox from '../components/BannerUploadBox'

const App = () => {
  const [logoPreview, setLogoPreview] = useState<string | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [bannerPreview, setBannerPreview] = useState<string | null>(null)
  const [bannerFile, setBannerFile] = useState<File | null>(null)
  const logoFileInput = useRef<HTMLInputElement>(null)
  const bannerFileInput = useRef<HTMLInputElement>(null)
  const BASE_PROVIDER_URL = 'https://base-pokt.nodies.app'
  let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
  const BaseFactoryAddress = '0x8FdB818c63e80D383DA9DE750c0088ABCba7ef02'
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const [tokenPercent, setTokenPercent] = useState(40)   // token percentage on fair launch
  const [ethLiquidity, setTokenLiquidity] = useState(50) // eth amount after fair launch
  const [tokenName, setTokenName] = useState('')
  const [raisedAmount, setRaisedAmount] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  let [loading, setLoading] = useState(false)
  let [creating, setCreating] = useState(false)
  const [website, setWebsite] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')
  const [firstConnect, setFirstConnect] = useState(false)
  const [whitelisted, setWhitelisted] = useState(false)
  const { open } = useWeb3Modal()

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  useEffect(() => {
    const FetchData = async () => {
      try {
        let whitelistedAddress = await readContract({
          address: BaseFactoryAddress,
          abi: FairLaunchFactoryAbi,
          functionName: 'whitelists',
          args: [address]
        })
        setWhitelisted(whitelistedAddress)
      } catch (e) {
        console.error(e)
      }
    }
    if (address) FetchData()
  }, [chain?.id, address])

  const onFairLaunchCreate = async () => {
    try {
      setCreating(true)
      let feeAmount
      if (whitelisted === true) {
        feeAmount = 0
      } else {
        feeAmount = 0.0015
      }
      let logoUrl
      let bannerUrl
      if (logoFile) {
        const formData = new FormData()
        formData.append('file', logoFile)
        fetch('https://dexmaker.zapto.org:13001/uploads', {
          method: 'POST',
          body: formData
        })
          .then(async res => {
            console.log('Success:', res)
            logoUrl = await res.json()
            logoUrl = logoUrl.fileInfo.filename
            if (bannerFile) {
              const formData = new FormData()
              formData.append('file', bannerFile)
              fetch('https://dexmaker.zapto.org:13001/uploads', {
                method: 'POST',
                body: formData
              })
                .then(async res => {
                  console.log('Success:', res)
                  bannerUrl = await res.json()
                  bannerUrl = bannerUrl.fileInfo.filename
                  console.log('debug', logoUrl, bannerUrl)
                  let create
                  if (bannerUrl && logoUrl) {
                    create = await writeContract({
                      address: BaseFactoryAddress,
                      abi: FairLaunchFactoryAbi,
                      functionName: 'createFairLaunch',
                      value: BaseWeb3.utils.toWei(String(feeAmount), 'ether'),
                      args: [
                        [
                          tokenName,
                          tokenSymbol,
                          tokenDescription,
                          logoUrl,
                          bannerUrl,
                          website,
                          twitter,
                          telegram,
                          discord
                        ],
                        [
                          BaseWeb3.utils.toWei(String(raisedAmount), 'ether'),
                          startDate,
                          endDate,
                          tokenPercent,
                          ethLiquidity,
                          BaseWeb3.utils.toWei(
                            String(Number(raisedAmount) / 10),
                            'ether'
                          )
                        ]
                      ]
                    })
                    await waitForTransaction({
                      hash: create.hash
                    })
                    toast.success(
                      `Successfully ${tokenName} FairLaunch created`
                    )
                    let presaleAddresses = await readContract({
                      address: BaseFactoryAddress,
                      abi: FairLaunchFactoryAbi,
                      functionName: 'getAllAddresses'
                    })
                    let presaleAddress =
                      presaleAddresses[presaleAddresses.length - 1]
                    const link = `/buy/?address=${presaleAddress}`
                    window.location.href = link
                  }
                  setCreating(false)
                })
                .catch(error => {
                  setCreating(false)
                  console.error('Error:', error)
                })
            }
          })
          .catch(error => {
            setCreating(false)
            console.error('Error:', error)
          })
      }
    } catch (err) {
      setCreating(false)
      toast.error(
        'There is a problem with your FairLaunch create. Please try again later'
      )
    }
  }

  const [faqVisibility, setFaqVisibility] = useState([false, false, false])

  const handleFAQClick = (e, index) => {
    const newVisibility = [...faqVisibility]
    newVisibility[index] = !newVisibility[index]
    newVisibility.forEach((item, i) => {
      if (i !== index) {
        newVisibility[i] = false
      }
    })
    setFaqVisibility(newVisibility)
  }

  const [startDateTime, setStartDateTime] = useState(new Date())
  const [startDate, setStartDate] = useState(0)

  const handleStartDateChange = date => {
    if (typeof date === 'object' && date !== null) {
      setStartDateTime(date)
      setStartDate(date.getTime() / 1000)
    } else {
      setStartDateTime(new Date())
      setStartDate(Number(new Date()) / 1000)
    }
  }
  const [endDateTime, setEndDateTime] = useState(new Date())
  const [endDate, setEndDate] = useState(0)

  const handleEndDateChange = date => {
    if (typeof date === 'object' && date !== null) {
      setEndDateTime(date)
      setEndDate(date.getTime() / 1000)
    } else {
      setEndDateTime(new Date())
      setEndDate(Number(new Date()) / 1000)
    }
  }

  const CustomTimeInput = () => {
    return (
      <div className="custom-time-input">
        <div className="hour-input">
          <select className="hour-select">
            {Array(24)
              .fill(0)
              .map((_, index) => (
                <option key={index} value={`${index}`}>
                  {`${index}`.padStart(2, '0')}
                </option>
              ))}
          </select>
          <span className="hour-separator">:</span>
        </div>
        <div className="minute-input">
          <select className="minute-select">
            {Array(60)
              .fill(0)
              .map((_, index) => (
                <option key={index} value={`${index}`}>
                  {`${index}`.padStart(2, '0')}
                </option>
              ))}
          </select>
        </div>
      </div>
    )
  }

  const CustomDatePicker = ({ value, onClick }) => {
    return (
      <div className="fairlaunch-date-time-wrapper">
        <DatePicker
          className="fairlaunch-date-time"
          selected={value}
          onChange={onClick}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          customInputRef={<CustomTimeInput />}
          placeholderText="Select date and time"
        />
        <img className="calendar-icon" src={calendarIcon} alt="calendar icon" />
      </div>
    )
  }

  const [, setImageLogoFile] = useState(null)

  const handleImageLogoChange = file => {
    setImageLogoFile(file)
  }

  const [, setImageBannerFile] = useState(null)

  const handleImageBannerChange = file => {
    setImageBannerFile(file)
  }

  const LogoImageUpload = ({ onChange, className, style }) => {
    const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files![0]
      setLogoFile(selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (logoFileInput.current) {
        logoFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={logoFileInput}
          accept="image/*"
          onChange={handleLogoImageChange}
          style={{ display: 'none' }}
        />
        <LogoUploadBox
          imageUrl={logoPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  const BannerImageUpload = ({ onChange, className, style }) => {
    const handleBannerImageChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const selectedFile = e.target.files![0]
      setBannerFile(selectedFile)
      setBannerPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (bannerFileInput.current) {
        bannerFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={bannerFileInput}
          accept="image/*"
          onChange={handleBannerImageChange}
          style={{ display: 'none' }}
        />
        <BannerUploadBox
          imageUrl={bannerPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  const setETHLiquidity = value => {
    setTokenLiquidity(value)
  }

  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar"></div>
            <h1 className="h1 title center">Fair Launch</h1>
            <div className="headerMargin" />
            <div
              className="MainDashboard ResponsiveFlexLayout"
              style={{ marginTop: '32px' }}
            >
              <section className="RightColumn">
                <>
                  <section>
                    <div className="MainContainerFairLaunch">
                      <div className="fairlaunch-token-info">1. Token Info</div>
                      <div
                        className="Text1"
                        style={{
                          marginLeft: '32px',
                          width: '100%',
                          fontSize: '14px'
                        }}
                      >
                        Provide your token information
                      </div>
                      <>
                        <section className="InputBox">
                          <section className="InputSection_Description">
                            <div className="LpBalance">
                              <p className="Text1">
                                Name<span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <Input
                                  placeholder="Enter Name"
                                  label=""
                                  type="text"
                                  changeValue={setTokenName}
                                  value={tokenName}
                                />
                              </section>
                            </section>
                          </section>
                        </section>

                        <section className="InputBox">
                          <section className="InputSection_Description">
                            <div className="LpBalance">
                              <p className="Text1">
                                Symbol<span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <Input
                                  placeholder="Enter Symbol"
                                  label=""
                                  type="text"
                                  changeValue={setTokenSymbol}
                                  value={tokenSymbol}
                                />
                              </section>
                            </section>
                          </section>
                        </section>

                        <section className="InputBoxUpload">
                          <section className="InputSection InputSectionLogo">
                            <div className="LpBalance">
                              <p className="Text1">
                                Upload Logo
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <LogoImageUpload
                                  onChange={handleImageLogoChange}
                                  className={undefined}
                                  style={undefined}
                                />
                              </section>
                            </section>
                          </section>

                          <section className="InputSection InputSectionBanner">
                            <div className="LpBalance">
                              <p className="Text1">
                                Upload Banner
                                <span style={{ color: 'red' }}>*</span>
                              </p>
                            </div>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <BannerImageUpload
                                  onChange={handleImageBannerChange}
                                  className="upload-box-banner"
                                  style={undefined}
                                />
                              </section>
                            </section>
                          </section>
                        </section>

                        <section className="InputBox">
                          <section className="InputSection_Description">
                            <p className="Text1">
                              Description (Max 1000 characters)
                              <span style={{ color: 'red' }}>*</span>
                            </p>
                            <section className="inputPanel">
                              <section className="inputPanelHeader">
                                <TextArea
                                  rows={6}
                                  placeholder="Enter Token Description"
                                  changeValue={setTokenDescription}
                                  value={tokenDescription}
                                />
                              </section>
                            </section>
                          </section>
                        </section>

                        <div className="fairlaunch-token-info">
                          2. Raise Details
                        </div>
                        <div
                          className="Text1"
                          style={{
                            marginLeft: '32px',
                            width: '100%',
                            fontSize: '14px'
                          }}
                        >
                          Set your raise dates and allocations.
                        </div>
                        <>
                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">
                                  Minimum Raise (ETH)
                                  <span style={{ color: 'red' }}>*</span>
                                </p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <Input
                                    placeholder="0"
                                    label=""
                                    type="number"
                                    changeValue={setRaisedAmount}
                                    value={raisedAmount}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>

                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">
                                  Start Date & Time
                                  <span style={{ color: 'red' }}>*</span>
                                </p>
                              </div>
                              <section className="inputPanel">
                                <section
                                  className="inputPanelHeader"
                                  style={{ zIndex: '2' }}
                                >
                                  <CustomDatePicker
                                    value={startDateTime}
                                    onClick={handleStartDateChange}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>

                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">
                                  End Date & Time
                                  <span style={{ color: 'red' }}>*</span>
                                </p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <CustomDatePicker
                                    value={endDateTime}
                                    onClick={handleEndDateChange}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>
                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">
                                  Token Percent (%)
                                  <span style={{ color: 'red' }}>*</span>
                                </p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <div className="fairlaunch-allocation-buttons-container">
                                  <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(40)
                                      }}
                                      style={
                                        tokenPercent === 40
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #5383fd, #6f7bce)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      40%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(50)
                                      }}
                                      style={
                                        tokenPercent === 50
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #5383fd, #6f7bce)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      50%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(60)
                                      }}
                                      style={
                                        tokenPercent === 60
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      60%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(70)
                                      }}
                                      style={
                                        tokenPercent === 70
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      70%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(80)
                                      }}
                                      style={
                                        tokenPercent === 80
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      80%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(90)
                                      }}
                                      style={
                                        tokenPercent === 90
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      90%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setTokenPercent(100)
                                      }}
                                      style={
                                        tokenPercent === 100
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      100%
                                    </button>
                                  </div>
                                </section>
                              </section>
                            </section>
                          </section>
                          <div
                            className="text"
                            style={{
                              maxWidth: '90%',
                              margin: '0',
                              fontSize: '14px',
                              textAlign: 'center'
                            }}
                          >
                            Enter the percentage of tokens on fair launch
                          </div>
                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">
                                  Liquidity (%)
                                  <span style={{ color: 'red' }}>*</span>
                                </p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <div className="fairlaunch-allocation-buttons-container">
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setETHLiquidity(50)
                                      }}
                                      style={
                                        ethLiquidity === 50
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #5383fd, #6f7bce)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      50%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setETHLiquidity(60)
                                      }}
                                      style={
                                        ethLiquidity === 60
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      60%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setETHLiquidity(70)
                                      }}
                                      style={
                                        ethLiquidity === 70
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      70%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setETHLiquidity(80)
                                      }}
                                      style={
                                        ethLiquidity === 80
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      80%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setETHLiquidity(90)
                                      }}
                                      style={
                                        ethLiquidity === 90
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      90%
                                    </button>
                                    <button
                                      className="fairlaunch-allocation-button"
                                      onClick={() => {
                                        setETHLiquidity(100)
                                      }}
                                      style={
                                        ethLiquidity === 100
                                          ? {
                                            background:
                                              'linear-gradient(to bottom, #9900c8, #ec040e)',
                                            color: 'white'
                                          }
                                          : {}
                                      }
                                    >
                                      100%
                                    </button>
                                  </div>
                                </section>
                              </section>
                            </section>
                          </section>
                          <div
                            className="text"
                            style={{
                              maxWidth: '90%',
                              margin: '0',
                              fontSize: '14px',
                              textAlign: 'center'
                            }}
                          >
                            Enter the percentage of raised funds that will be
                            added to Liquidity
                          </div>
                        </>

                        <br />

                        <div className="fairlaunch-token-info">
                          3. Additional Information
                        </div>
                        <div
                          className="Text1"
                          style={{
                            marginLeft: '32px',
                            width: '100%',
                            fontSize: '14px'
                          }}
                        >
                          Let people connect with you.
                        </div>
                        <>
                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">Website</p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <Input
                                    placeholder="https://"
                                    label=""
                                    type="text"
                                    changeValue={setWebsite}
                                    value={website}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>

                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">Telegram</p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <Input
                                    placeholder="https://"
                                    label=""
                                    type="text"
                                    changeValue={setTelegram}
                                    value={telegram}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>

                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">Discord</p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <Input
                                    placeholder="https://"
                                    label=""
                                    type="text"
                                    changeValue={setDiscord}
                                    value={discord}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>

                          <section className="InputBox">
                            <section className="InputSection_Description">
                              <div className="LpBalance">
                                <p className="Text1">X.com</p>
                              </div>
                              <section className="inputPanel">
                                <section className="inputPanelHeader">
                                  <Input
                                    placeholder="https://"
                                    label=""
                                    type="text"
                                    changeValue={setTwitter}
                                    value={twitter}
                                  />
                                </section>
                              </section>
                            </section>
                          </section>
                        </>
                      </>
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%'
                          }}
                        >
                          {creating === false ? (
                            isConnected ? (
                              <button
                                disabled={
                                  tokenName === '' ||
                                  tokenSymbol === '' ||
                                  tokenDescription === ''
                                }
                                onClick={onFairLaunchCreate}
                                className="CreateButton"
                              >
                                {tokenName === '' ||
                                  tokenSymbol === '' ||
                                  tokenDescription === '' ||
                                  raisedAmount === '' ||
                                  logoFile === null ||
                                  bannerFile === null ||
                                  startDate === 0 ||
                                  endDate === 0
                                  ? 'Please Enter FairLaunch Details'
                                  : 'Create FairLaunch Now!'}
                              </button>
                            ) : (
                              <>
                                <button
                                  className="CreateButton"
                                  type="submit"
                                  onClick={() => {
                                    onConnectWallet()
                                  }}
                                >
                                  Connect
                                  <span className="navWallet"> Wallet</span>
                                </button>
                              </>
                            )
                          ) : (
                            <div className="loadingBox">
                              <p className="Text1">Creating...</p>
                              <ClipLoader
                                color={'#afccc6'}
                                loading={creating}
                                size={30}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          )}
                        </div>
                      </>
                      <div
                        className="fairlaunch-token-info"
                        style={{
                          maxWidth: '90%',
                          margin: '0',
                          fontSize: '14px',
                          textAlign: 'center',
                          width: '100%'
                        }}
                      >
                        {whitelisted ? <></> : <>Launch Cost 0.0015 ETH</>}
                      </div>
                    </div>

                    <div style={{ marginBottom: '72px' }} />
                  </section>
                </>
              </section>
              <section className="LeftColumn">
                <h1
                  className="ContractContentTextTitle h1"
                  style={{ marginTop: '-6px' }}
                >
                  How to create Token Fair Launch
                </h1>
                <p className="text">
                  Follow this guide to effortlessly create your own Fair Launch
                  Token sale on the BASE Network. No coding required.
                </p>
                <br />
                <h3>Token Info</h3>
                <p>
                  {' '}
                  <ol>
                    <li>Connect your Wallet.</li>
                    <li>Specify the Name of your new Token.</li>
                    <li>
                      Enter the Token Symbol (Ticker) Maximum of 8 characters.
                    </li>
                    <li>Upload the Token image. (recommended 150 x 150px.)</li>
                    <li>
                      Upload the banner image to be used for your Tokens details
                      page. (recommended 800 x 200px.){' '}
                    </li>
                    <li>
                      Provide a description of your Token. Max 1000 characters.
                    </li>
                  </ol>
                </p>
                <br />
                <h3>Raise Details</h3>
                <p>
                  {' '}
                  <ol>
                    <li>
                      Select the Minimum raise (soft-cap) you are looking to
                      generate on the platform in ETH.
                    </li>
                    <li>Set the Start date & time for the launch. </li>
                    <li>Set the End date & time for launch.</li>
                    <li>
                      Select the % of token supply that will be allocated to the
                      Fair-Launch sale.
                    </li>
                    <li>
                      Decide what % of raised funds will be transferred into the
                      liquidity pool at the end of the sale. (Minimum of 50%.)
                    </li>
                  </ol>
                </p>
                <br />
                <h3>Additional Information</h3>
                <p>
                  <ol>
                    <li>Add your website and social links. </li>
                    <li>
                      Select “Create Token Launch” button and accept the
                      transaction in your wallet.
                    </li>
                    <li>
                      Your Token Fair-Launch will now be live on DexMaker.
                    </li>
                  </ol>
                </p>
              </section>
            </div>
          </div>
        }
        <section className="FAQ">
          <div className="textContainer">
            <h4>Frequently Asked Questions</h4>
            <div className="faqItems">
              <div className="faqItem">
                <div
                  className="faqTitle"
                  onClick={e => handleFAQClick(e, 0)}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ flex: 1 }}>
                    <p>What is a Fair Launch?</p>
                  </span>
                  <img
                    src="/img/chevron-down-svgrepo-com.svg"
                    width="16"
                    height="16"
                    className={`${faqVisibility[0] ? '' : 'rotate'}`}
                    alt="Chevron down icon"
                  />
                </div>
                <div
                  className="faqAnswer"
                  style={{ display: faqVisibility[0] ? 'block' : 'none' }}
                >
                  <p>
                    A fair launch refers to the distribution of a new
                    cryptocurrency or token in a way that is equitable and
                    transparent for all participants. In a fair launch, there
                    are no pre-mined tokens allocated to developers or insiders
                    before the public release. Instead, everyone has an equal
                    opportunity to obtain the Token at the same price on launch.
                    The goal of a fair launch is to create a level playing field
                    and prevent any unfair advantage for certain individuals or
                    groups. This approach is often favoured by proponents of
                    decentralization and community-driven projects, as it aligns
                    with the principles of decentralization, transparency, and
                    inclusivity.
                  </p>
                </div>
                <hr />
              </div>
              <div className="faqItem">
                <div
                  className="faqTitle"
                  onClick={e => handleFAQClick(e, 1)}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ flex: 1 }}>
                    <p>
                      Is it safe to Launch using{' '}
                      <span className="dexmaker">DexMaker</span>?
                    </p>
                  </span>
                  <img
                    src="/img/chevron-down-svgrepo-com.svg"
                    width="16"
                    height="16"
                    className={`${faqVisibility[1] ? '' : 'rotate'}`}
                    alt="Chevron down icon"
                  />
                </div>
                <div
                  className="faqAnswer"
                  style={{ display: faqVisibility[1] ? 'block' : 'none' }}
                >
                  <p>
                    Yes, it is safe to create and launch tokens using{' '}
                    <span className="dexmaker">DexMaker</span>. Our platform
                    prioritizes the security and privacy of our users.
                  </p>
                </div>
                <hr />
              </div>
              <div className="faqItem">
                <div
                  className="faqTitle"
                  onClick={e => handleFAQClick(e, 2)}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ flex: 1 }}>
                    <p>How much time will it take to Launch my Token?</p>
                  </span>
                  <img
                    src="/img/chevron-down-svgrepo-com.svg"
                    width="16"
                    height="16"
                    className={`${faqVisibility[2] ? '' : 'rotate'}`}
                    alt="Chevron down icon"
                  />
                </div>
                <div
                  className="faqAnswer"
                  style={{ display: faqVisibility[2] ? 'block' : 'none' }}
                >
                  <p>
                    Creating a Fair-Launch on{' '}
                    <span className="dexmaker">DexMaker</span> is a quick and
                    efficient process, taking only moments to complete. You'll
                    have your new Token listed and live on our launchpad in a
                    matter of moments
                  </p>
                </div>
                <hr />
              </div>
              <div className="faqItem">
                <div
                  className="faqTitle"
                  onClick={e => handleFAQClick(e, 3)}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ flex: 1 }}>
                    <p>How much does it cost to make a Token?</p>
                  </span>
                  <img
                    src="/img/chevron-down-svgrepo-com.svg"
                    width="16"
                    height="16"
                    className={`${faqVisibility[3] ? '' : 'rotate'}`}
                    alt="Chevron down icon"
                  />
                </div>
                <div
                  className="faqAnswer"
                  style={{ display: faqVisibility[3] ? 'block' : 'none' }}
                >
                  <p>
                    The price for creating a token and launching on{' '}
                    <span className="dexmaker">DexMaker</span> is currently 0.0015
                    ETH. <span className="dexmaker">DexMaker</span> reserve the
                    right to adjust launch prices up and down in accordance to
                    market fluctuations and demand.
                  </p>
                </div>
                <hr />
              </div>
              <div className="faqItem">
                <div
                  className="faqTitle"
                  onClick={e => handleFAQClick(e, 4)}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ flex: 1 }}>
                    <p>What wallets can I use?</p>
                  </span>
                  <img
                    src="/img/chevron-down-svgrepo-com.svg"
                    width="16"
                    height="16"
                    className={`${faqVisibility[4] ? '' : 'rotate'}`}
                    alt="Chevron down icon"
                  />
                </div>
                <div
                  className="faqAnswer"
                  style={{ display: faqVisibility[4] ? 'block' : 'none' }}
                >
                  <p>
                    You can use any wallet that supports the BASE network to
                    create and manage your tokens with{' '}
                    <span className="dexmaker">DexMaker</span>. We recommend to
                    use the Coinbase Wallet or Metamask.
                  </p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </section>
        <section className="FAQ">
          <div className="textContainer">
            <h4>Why Launch Tokens using DexMaker</h4>
            <p>
              Whether you're a seasoned developer or new to the scene, our{' '}
              <span className="dexmaker">DexMaker</span> Fair Launchpad is
              crafted with you in mind. Discover the simplicity of swiftly and
              safely Launching tokens, preserving your precious time and
              resources. What distinguishes us is our steadfast dedication to
              outstanding assistance.
            </p>
            <p>
              Our devoted team of specialists stands ready 24/7 to tackle any
              questions or obstacles you may face. Embark on your venture of
              crafting and overseeing BASE tokens with assurance today, knowing
              that our dependable and protected online builder delivers an
              unmatched journey. You won't encounter a more intuitive and
              effective solution elsewhere!
            </p>
          </div>
          <br />
          <br />
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default App
